.site-dashboard {
  padding: 20px;
  background-color: #121212;
  min-height: 100vh;
  color: #e0e0e0;
}

/* Header */
.site-dashboard-header {
  margin-bottom: 30px;
}

.site-dashboard-header h1 {
  color: #fff;
  font-size: 24px;
  margin: 0;
}

/* Filtros */
.site-filters {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.site-filters label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #808080;
  font-size: 14px;
}

.site-filters select,
.site-filters input {
  padding: 8px 12px;
  border: 1px solid #404040;
  border-radius: 4px;
  background-color: #2d2d2d;
  color: #e0e0e0;
  font-size: 14px;
  min-width: 150px;
}

.site-range-inputs {
  display: flex;
  gap: 8px;
  align-items: center;
}

.site-range-inputs input {
  width: 100px;
}

.site-metrics-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.site-metric-card {
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.site-metric-card:hover {
  transform: scale(1.05);
}

.site-metric-card h3 {
  color: #999;
  font-size: 1rem;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.site-metric-card p {
  font-size: 1.8rem;
  font-weight: bold;
  color: #6200ee;
  margin: 0;
}

.site-charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.site-chart-box {
  background: #1e1e1e;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 20px;
}

.site-chart-box h3 {
  color: #808080;
  font-size: 16px;
  margin: 0 0 20px 0;
  text-align: center;
}

.site-loading,
.site-error {
  text-align: center;
  padding: 40px;
  font-size: 18px;
  color: #e0e0e0;
}

.site-error {
  color: #ff5252;
}

/* Cards de Métricas */
.site-metric-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.site-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.site-metric-card h3 {
  color: #808080;
  font-size: 14px;
  margin: 0 0 16px 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.site-metric-value {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: auto;
}

/* Adicionar animação para os valores */
@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.site-metric-value {
  animation: countUp 0.5s ease-out forwards;
}

/* Containers de Gráficos */
.site-chart-box {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.site-chart-box h3 {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos específicos para gráficos SEO */
.site-seo-chart {
  height: 300px;
}

.site-seo-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  padding: 15px;
  background: #252525;
  border-radius: 8px;
  margin-top: 15px;
}

.site-seo-metric-item {
  text-align: center;
  padding: 10px;
}

.site-seo-metric-item span {
  display: block;
  color: #b3b3b3;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.site-seo-metric-item strong {
  color: #6200ee;
  font-size: 1.2rem;
}

/* Tabela de Dados */
.site-table-container {
  background: #1e1e1e;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.site-table-actions {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}

.site-icon-button {
  background: transparent;
  border: 1px solid #404040;
  border-radius: 4px;
  padding: 8px;
  color: #e0e0e0;
  cursor: pointer;
  transition: all 0.2s;
}

.site-icon-button:hover {
  background: #404040;
}

.site-data-table table {
  width: 100%;
  border-collapse: collapse;
  color: #e0e0e0;
}

.site-data-table th {
  background: #2d2d2d;
  padding: 12px;
  text-align: left;
  cursor: pointer;
  user-select: none;
}

.site-data-table th:hover {
  background: #404040;
}

.site-data-table td {
  padding: 12px;
  border-bottom: 1px solid #333;
}

.site-sort-icon {
  margin-left: 8px;
  opacity: 0.5;
}

.site-sort-icon.active {
  opacity: 1;
}

.site-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  color: #e0e0e0;
}

.site-rows-per-page {
  display: flex;
  align-items: center;
  gap: 8px;
}

.site-rows-per-page select {
  background: #2d2d2d;
  border: 1px solid #404040;
  color: #e0e0e0;
  padding: 4px 8px;
  border-radius: 4px;
}

.site-page-navigation {
  display: flex;
  align-items: center;
  gap: 16px;
}

.site-page-button {
  background: transparent;
  border: 1px solid #404040;
  color: #e0e0e0;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.site-page-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.site-page-button:not(:disabled):hover {
  background: #404040;
}

/* Status Indicators */
.site-status-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.site-status-good {
  background-color: #4caf50;
}

.site-status-warning {
  background-color: #ff9800;
}

.site-status-error {
  background-color: #f44336;
}

/* Performance Metrics */
.site-performance-indicator {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-radius: 4px;
  background: #2a2a2a;
}

.site-performance-indicator .value {
  font-size: 1.2rem;
  font-weight: bold;
}

.site-performance-indicator .label {
  color: #b3b3b3;
  font-size: 0.9rem;
}

/* Link Analysis */
.site-link-analysis {
  background: #2a2a2a;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
}

.site-link-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
}

/* Responsividade */
@media (max-width: 768px) {
  .site-charts-container {
    grid-template-columns: 1fr;
  }
  
  .site-metric-cards {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  
  .site-filters {
    grid-template-columns: 1fr;
  }
  
  .site-seo-metrics {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .site-metric-card {
    padding: 20px;
    min-height: 120px;
  }
  
  .site-metric-value {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .site-metric-cards {
    grid-template-columns: 1fr;
  }
  
  .site-metric-card {
    padding: 15px;
  }
  
  .site-metric-card p {
    font-size: 1.4rem;
  }
}

/* Tooltips personalizados */
.site-custom-tooltip {
  background: rgba(37, 37, 37, 0.95);
  border: 1px solid #444;
  padding: 10px;
  border-radius: 4px;
}

.site-custom-tooltip p {
  margin: 0;
  padding: 3px 0;
  color: white;
}

/* Paginação */
.site-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #1a1a1a;
  border-top: 1px solid #444;
}

.site-pagination select {
  background: #333;
  border: 1px solid #444;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
}

.site-pagination button {
  background: #6200ee;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.site-pagination button:disabled {
  background: #444;
  cursor: not-allowed;
}

/* Loading State */
.site-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2rem;
  color: #b3b3b3;
}

.site-filters-section {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  border: 1px solid #333;
}

.site-filter-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.site-filter-group label {
  font-weight: 500;
  color: #e0e0e0;
  min-width: 80px;
}

.site-filter-group select,
.site-filter-group input {
  padding: 8px 12px;
  border: 1px solid #404040;
  border-radius: 4px;
  font-size: 14px;
  background-color: #2d2d2d;
  color: #e0e0e0;
}

.site-filter-group select {
  min-width: 150px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23e0e0e0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  padding-right: 32px;
}

.site-filter-group input {
  min-width: 200px;
}

.site-filter-group select:focus,
.site-filter-group input:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 1px #0066cc;
}

/* Estilo para as options do select */
.site-filter-group select option {
  background-color: #2d2d2d;
  color: #e0e0e0;
}

/* Estilo para placeholder */
.site-filter-group input::placeholder {
  color: #808080;
}

/* Hover states */
.site-filter-group select:hover,
.site-filter-group input:hover {
  border-color: #4d4d4d;
}

/* Disabled states */
.site-filter-group select:disabled,
.site-filter-group input:disabled {
  background-color: #252525;
  color: #666;
  cursor: not-allowed;
}

/* Estilo para o grupo de inputs de range */
.site-range-inputs {
  display: flex;
  align-items: center;
  gap: 8px;
}

.site-range-separator {
  color: #e0e0e0;
  font-size: 14px;
}

/* Ajuste para inputs numéricos */
.site-range-inputs input[type="number"] {
  width: 100px;
  padding: 8px 12px;
  background-color: #2d2d2d;
  color: #e0e0e0;
  border: 1px solid #404040;
  border-radius: 4px;
}

/* Remove as setas do input number */
.site-range-inputs input[type="number"]::-webkit-inner-spin-button,
.site-range-inputs input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.site-range-inputs input[type="number"] {
  -moz-appearance: textfield;
}

/* Estados de hover e focus para os inputs numéricos */
.site-range-inputs input[type="number"]:hover {
  border-color: #4d4d4d;
}

.site-range-inputs input[type="number"]:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 1px #0066cc;
}

/* Placeholder para inputs numéricos */
.site-range-inputs input[type="number"]::placeholder {
  color: #808080;
}

/* Ajuste específico para o select de Status Code */
.site-filter-group select[value="all"] {
  color: #808080;
}

/* Estilo para as options do Status Code */
.site-filter-group select option {
  padding: 8px;
}

/* Ajuste para o grupo de filtros quando em telas menores */
@media (max-width: 768px) {
  .site-filter-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .site-range-inputs {
    width: 100%;
  }

  .site-range-inputs input[type="number"] {
    flex: 1;
  }
}

/* Estilo específico para o select de Status */
.site-status-select {
  min-width: 200px;
  padding: 8px 12px;
  border: 1px solid #404040;
  border-radius: 4px;
  font-size: 14px;
  background-color: #2d2d2d;
  color: #e0e0e0;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23e0e0e0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  padding-right: 32px;
  cursor: pointer;
}

.site-status-select:hover {
  border-color: #4d4d4d;
}

.site-status-select:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 1px #0066cc;
}

.site-status-select option {
  background-color: #2d2d2d;
  color: #e0e0e0;
  padding: 8px;
}

/* Ajuste para o valor "all" */
.site-status-select option[value="all"] {
  color: #808080;
}

/* Adicionar ao SiteDashboard.css */
.site-column-selector-container {
  position: relative;
}

.site-column-selector-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: #1e1e1e;
  border: 1px solid #333;
  border-radius: 8px;
  width: 250px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.site-column-selector-header {
  padding: 12px;
  border-bottom: 1px solid #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-column-selector-header h3 {
  margin: 0;
  font-size: 14px;
  color: #e0e0e0;
}

.site-select-all-button {
  background: none;
  border: none;
  color: #6200ee;
  cursor: pointer;
  font-size: 12px;
  padding: 4px 8px;
}

.site-select-all-button:hover {
  text-decoration: underline;
}

.site-column-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 8px;
}

.site-column-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #e0e0e0;
}

.site-column-option:hover {
  background: #333;
}

.site-column-option input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.site-header-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Estilizar scrollbar */
.site-column-list::-webkit-scrollbar {
  width: 8px;
}

.site-column-list::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.site-column-list::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 4px;
}

.site-column-list::-webkit-scrollbar-thumb:hover {
  background: #444;
}
