.youtube-dashboard {
  padding: 20px;
  background-color: #000000;
  color: white;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
}

h1 {
  text-align: center;
  margin-top: 65px;
  color: #ffffff;
  margin-bottom: 20px;
}

/* Filtros */
.youtube-filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  background: #1a1a1a;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.youtube-filters label {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.youtube-filters select,
.youtube-filters input {
  padding: 8px 12px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: white;
  font-size: 0.9rem;
  width: 100%;
}

.youtube-filters .youtube-range-inputs {
  display: flex;
  gap: 10px;
}

.youtube-filters .youtube-range-inputs input {
  width: 50%;
}

.youtube-filters select:hover,
.youtube-filters input:hover {
  border-color: #6200ee;
}

.youtube-filters select:focus,
.youtube-filters input:focus {
  border-color: #6200ee;
  outline: none;
  box-shadow: 0 0 0 2px rgba(98, 0, 238, 0.2);
}

/* Cards de Métricas */
.youtube-metrics-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.youtube-metric-card {
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.youtube-metric-card:hover {
  transform: scale(1.05);
}

.youtube-metric-card h3 {
  color: #999;
  font-size: 1rem;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.youtube-metric-card p {
  font-size: 1.8rem;
  font-weight: bold;
  color: #6200ee;
  margin: 0;
}

/* Tabela de Dados */
.youtube-data-table {
  overflow-x: auto;
  background: #1a1a1a;
  padding: 15px;
  border-radius: 8px;
}

.youtube-data-table table {
  width: 100%;
  border-collapse: collapse;
}

.youtube-data-table th, .youtube-data-table td {
  border: 1px solid #444;
  padding: 12px;
  text-align: left;
}

.youtube-data-table th {
  background-color: #6200ee;
  color: white;
  text-transform: uppercase;
}

.youtube-data-table tr:nth-child(even) {
  background-color: #2a2a2a;
}

.youtube-data-table tr:hover {
  background-color: #1a1a1a;
}

/* Paginação */
.youtube-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-top: 1px solid #444;
  background: #333;
}

.youtube-pagination button {
  background: #6200ee;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.youtube-pagination button:hover {
  background: #57a878;
}

.youtube-pagination button:disabled {
  background: #444;
  cursor: not-allowed;
}

.youtube-pagination span {
  color: white;
  font-size: 1rem;
}

/* Carregamento */
.youtube-loading, .youtube-no-data {
  text-align: center;
  font-size: 1.2rem;
  color: #ccc;
}

.youtube-charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.youtube-chart-box {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.youtube-chart-box h3 {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .youtube-charts-container {
    grid-template-columns: 1fr;
  }
  
  .youtube-metric-card {
    padding: 15px;
  }
  
  .youtube-metric-card p {
    font-size: 1.4rem;
  }

  .youtube-filters {
    grid-template-columns: 1fr;
    padding: 15px;
  }

  .youtube-filters .youtube-range-inputs {
    flex-direction: row;
  }
}

.youtube-correlations-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  margin: 30px 0;
}

.youtube-correlation-box {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.youtube-correlation-box h3 {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 20px;
  text-align: center;
}

.youtube-custom-tooltip {
  background: rgba(37, 37, 37, 0.95);
  border: 1px solid #444;
  padding: 10px;
  border-radius: 4px;
  color: white;
}

.youtube-custom-tooltip p {
  margin: 0;
  padding: 3px 0;
}

@media (max-width: 768px) {
  .youtube-correlations-container {
    grid-template-columns: 1fr;
  }
}

.youtube-dashboard-actions {
  display: flex;
  gap: 10px;
}

.youtube-action-button {
  background: #6200ee;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: background-color 0.2s;
}

.youtube-action-button:hover {
  background: #7722ff;
}

.youtube-action-button:disabled {
  background: #666;
  cursor: not-allowed;
}

.youtube-table-container {
  background: #252525;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
}

.youtube-table-actions {
  display: flex;
  gap: 8px;
  padding: 12px;
  border-bottom: 1px solid #444;
  align-items: center;
  justify-content: flex-end;
}

.youtube-icon-button {
  background: transparent;
  border: none;
  color: #6200ee;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.youtube-icon-button:hover {
  background: rgba(98, 0, 238, 0.1);
}

.youtube-icon-button:disabled {
  color: #666;
  cursor: not-allowed;
}

.youtube-icon-button svg {
  width: 16px;
  height: 16px;
}

.youtube-column-selector-container {
  position: relative;
}

.youtube-column-selector-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  margin-top: 8px;
  background: #333;
  border: 1px solid #444;
  border-radius: 4px;
  width: 250px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.youtube-column-selector-header {
  padding: 12px;
  border-bottom: 1px solid #444;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.youtube-column-selector-header h3 {
  margin: 0;
  font-size: 14px;
  color: #fff;
}

.youtube-select-all-button {
  background: none;
  border: none;
  color: #6200ee;
  cursor: pointer;
  font-size: 12px;
  padding: 4px 8px;
}

.youtube-select-all-button:hover {
  text-decoration: underline;
}

.youtube-column-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 8px;
}

.youtube-column-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #fff;
}

.youtube-column-option:hover {
  background: #444;
}

.youtube-column-option input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.youtube-data-table {
  overflow-x: auto;
}

.youtube-data-table table {
  width: 100%;
  border-collapse: collapse;
}

.youtube-data-table th {
  background: #333;
  padding: 12px 16px;
  text-align: left;
  font-weight: 500;
  color: #fff;
  border-bottom: 1px solid #444;
  white-space: nowrap;
}

.youtube-data-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #444;
  color: #fff;
}

.youtube-data-table tr:hover td {
  background: #333;
}

/* Estilizar scrollbar */
.youtube-column-list::-webkit-scrollbar {
  width: 8px;
}

.youtube-column-list::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.youtube-column-list::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

.youtube-column-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.youtube-sortable-header {
  cursor: pointer;
  user-select: none;
}

.youtube-header-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.youtube-sort-icon {
  font-size: 12px;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.youtube-sort-icon.active {
  opacity: 1;
  color: #6200ee;
}

.youtube-sortable-header:hover .youtube-sort-icon {
  opacity: 1;
}

.youtube-rows-per-page {
  display: flex;
  align-items: center;
  gap: 12px;
}

.youtube-rows-per-page select {
  background: #252525;
  border: 1px solid #444;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.youtube-page-navigation {
  display: flex;
  align-items: center;
  gap: 16px;
}

.youtube-page-button {
  background: transparent;
  border: none;
  color: white;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.youtube-page-button:hover:not(:disabled) {
  background: #444;
}

.youtube-page-button:disabled {
  color: #666;
  cursor: not-allowed;
}

/* Ajuste para garantir que a tabela ocupe todo o espaço disponível */
.youtube-data-table table {
  min-width: 100%;
}

/* Estilo para células vazias */
.youtube-data-table td:empty::after {
  content: "-";
  color: #666;
}